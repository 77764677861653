import * as React from 'react';

import { ReactComponent as AllianzSvg } from '../../images/svg/clients/allianz.svg';
import { ReactComponent as BritishAirwaysSvg } from '../../images/svg/clients/british-airways.svg';
import { ReactComponent as CathayPacificSvg } from '../../images/svg/clients/cathay-pacific.svg';
import { ReactComponent as CciSvg } from '../../images/svg/clients/cci.svg';
import { ReactComponent as CloudinarySvg } from '../../images/svg/clients/cloudinary.svg';
import { ReactComponent as CoalfireSvg } from '../../images/svg/clients/coalfire.svg';
import { ReactComponent as CondeNastSvg } from '../../images/svg/clients/conde-nast.svg';
import { ReactComponent as ContentfulSvg } from '../../images/svg/clients/contentful.svg';
import { ReactComponent as DhlSvg } from '../../images/svg/clients/dhl.svg';
import { ReactComponent as EileenFisherSvg } from '../../images/svg/clients/eileen-fisher.svg';
import { ReactComponent as F5Svg } from '../../images/svg/clients/f5.svg';
import { ReactComponent as InvisionSvg } from '../../images/svg/clients/invision.svg';
// import { ReactComponent as KingspanSvg } from '../../images/svg/clients/kingspan.svg';
import { ReactComponent as KnockCrmSvg } from '../../images/svg/clients/knock-crm.svg';
import { ReactComponent as LimeSvg } from '../../images/svg/clients/lime.svg';
import { ReactComponent as MassMutualSvg } from '../../images/svg/clients/massmutual.svg';
import { ReactComponent as MercariSvg } from '../../images/svg/clients/mercari.svg';
import { ReactComponent as MitsiSvg } from '../../images/svg/clients/mitsubishi-motors.svg';
import { ReactComponent as NbcUniversalSvg } from '../../images/svg/clients/nbcuniversal.svg';
import { ReactComponent as NhsSvg } from '../../images/svg/clients/nhs.svg';
import { ReactComponent as PanasonicSvg } from '../../images/svg/clients/panasonic.svg';
import { ReactComponent as RobinHoodSvg } from '../../images/svg/clients/robinhood.svg';
import { ReactComponent as SaksSvg } from '../../images/svg/clients/saks.svg';
import { ReactComponent as SamsaraSvg } from '../../images/svg/clients/samsara.svg';
import { ReactComponent as SamsungSvg } from '../../images/svg/clients/samsung.svg';
import { ReactComponent as SeismicSvg } from '../../images/svg/clients/seismic.svg';
import { ReactComponent as StroeerSvg } from '../../images/svg/clients/stroeer.svg';
// import { ReactComponent as TokopediaSvg } from '../../images/svg/clients/tokopedia.svg';
import { ReactComponent as VonageSvg } from '../../images/svg/clients/vonage.svg';

const logos = [
  <AllianzSvg />,
  <BritishAirwaysSvg />,
  <CathayPacificSvg />,
  // <CciSvg />,
  // <CloudinarySvg />,
  // <CoalfireSvg />,
  <CondeNastSvg />,
  <ContentfulSvg />,
  <DhlSvg />,
  <EileenFisherSvg />,
  <F5Svg />,
  <InvisionSvg />,
  // <KnockCrmSvg />,
  <LimeSvg />,
  <MassMutualSvg />,
  <MercariSvg />,
  <MitsiSvg />,
  <NbcUniversalSvg />,
  <NhsSvg />,
  <PanasonicSvg />,
  <RobinHoodSvg />,
  <SaksSvg />,
  <SamsaraSvg />,
  <SamsungSvg />,
  // <SeismicSvg />,
  <StroeerSvg />,
  <VonageSvg />,
];

const CustomerLogos = () => (
  <section
    id="trusted-by"
    className="text-gray-500 bg-white dark:bg-gray-800 dark:text-gray-500"
  >
    <h2 className="pt-10 -mb-4 text-xl font-semibold text-center md:text-2xl">
      Trusted by industry leaders
    </h2>
    <div className="flex items-center w-full overflow-hidden">
      <div className="flex items-center gap-12 px-8 py-4 overflow-x-scroll h-36 hz-scrollbar ">
        {logos.map((item) => (
          <div>{item}</div>
        ))}
      </div>
    </div>
  </section>
);

export default CustomerLogos;
