import * as React from 'react';
import { Link } from 'gatsby';

import Rule from '../rule';
import Section from '../section';

import { ReactComponent as MondayHeroSvg } from '../../images/svg/undraw/monday.svg';

const Monday = () => (
  <Section
    className="hero-y"
    innerClassName="max-w-screen-xl px-5 lg:px-16 py-16 mx-auto"
  >
    <section className="body-font hero-text">
      <div className="container flex flex-col mx-auto md:flex-row">
        <div className="hero-svg-wrapper">
          <MondayHeroSvg
            className="max-w-xs md:max-w-md m-0"
            style={{ margin: 'auto' }}
          />
        </div>

        <div className="hero-text-wrapper xs:ml-0 md:pl-12">
          <Rule classNames="dark:bg-indigo-500 bg-indigo-200" />
          <h2 className="mb-4 text-4xl leading-relaxed">
            monday.com apps <br className="hidden lg:inline-block" />
            for every day
          </h2>
          {/* <p className="mb-4 leading-relaxed font-serif font-medium text-lg">
                  for <em>every</em> day of the week
                </p> */}
          <div className="flex mt-4">
            <Link to="/products/#/monday.com" className="button-primary">
              View monday.com apps
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Section>
);

export default Monday;
