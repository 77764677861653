import * as React from 'react';

import { ReactComponent as EyeSvg } from '../../images/svg/remixicon/eye.svg';
import { ReactComponent as UserSvg } from '../../images/svg/remixicon/user.svg';
import { ReactComponent as GlobeSvg } from '../../images/svg/remixicon/globe.svg';

const stats = {
  users: '40m',
  countries: '240',
  views: '400m',
};

const Item = ({ IconSvg, stat, description }) => (
  <div className="flex flex-col items-center w-1/3 p-4 text-center">
    <div className="flex-grow">
      <div className="mb-3 font-serif text-lg font-medium  title-font">
        <span className="text-2xl md:text-4xl ">{stat}</span>
        <div className="pt-3 text-base md:text-lg">{description}</div>
      </div>
    </div>
    <div className="inline-flex items-center justify-center flex-shrink-0 mb-5 w-14 h-14">
      <IconSvg />
    </div>
  </div>
);

const countriesDescription = () => (
  <>
    countries
    {/* <br />
    &amp; territories */}
  </>
);

const HomeStats = () => (
  <section id="index-stats" className="hero-z body-font">
    <div className="container px-5 lg:px-16 pt-10 mx-auto lg:w-5/6 xl:w-3/5 ">
      <div className="flex flex-wrap -mx-4 -mt-4 -mb-10 space-y-0 sm:-m-4 ">
        <Item IconSvg={UserSvg} stat={stats.users} description="users" />
        <Item IconSvg={EyeSvg} stat={stats.views} description="app views" />
        <Item
          IconSvg={GlobeSvg}
          stat={stats.countries}
          description={countriesDescription()}
        />
      </div>
    </div>
    <div className="visible-on-parent-hover">
      As measured by Google Analytics, since 2017.
    </div>
  </section>
);

export default HomeStats;
