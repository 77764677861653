import * as React from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Layout from '../components/layout';
import Atlassian from '../components/home/atlassian';
import Monday from '../components/home/monday';
import HomeStats from '../components/home/home-stats';
import CustomerLogos from '../components/home/customer-logos';

// import AnimateElement from '../animations/animate-element';

// import { ReactComponent as AnimatedBgSvg } from '../images/svg/bgjar/animated-blobs.svg';

const title = 'David Simpson Apps | Apps for monday.com & Atlassian';
const IndexPage = () => (
  <>
    <GatsbySeo
      language="en"
      title={title}
      description="monday.com apps for every day • Atlassian Apps that get you further • We build apps for customers who expect performance at scale."
      openGraph={{
        title,
      }}
    />
    <Layout pageTitle={title} overrideTitle={true}>
      <div className="__hero-x">
        <Monday />
        <Atlassian />
      </div>

      <HomeStats />
      <CustomerLogos />
    </Layout>
  </>
);

export default IndexPage;
